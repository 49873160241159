<template>
  <div
    class="placard_preview_container"
    @click="closePreview"
    v-if="this.placardURL"
  >
    <div class="placard_image">
      <!-- <CustomImage :src="placardURL"></CustomImage> -->
      <CustomButton :round="true" size="small" @click="closePreview"
        >关闭</CustomButton
      >
      <div class="placard_title">长按图片可保存/分享</div>
    </div>
  </div>
</template>
  <script>
import "vant/es/image-preview/style";
import { ImagePreview } from "vant";
import CustomImage from "@/components/unit/CustomImage";
import CustomButton from "@/components/unit/CustomButton";
export default {
  name: "headlinesNew",
  components: {
    CustomImage,
    CustomButton,
    [ImagePreview.Component.name]: ImagePreview,
  },
  data() {
    return {
      placardURL: "",
      periods: "",
      loading: null,
      show: false,
      vanImagePreview: null,
      src: [
        "https://files.oss.medflying.com/aikangfei/双眼圆锥角膜/detail/P2/角膜地形图OD.png",
      ],
      index: 0,
      imagePreview: null,
    };
  },

  props: {
    placardCanvasRef: {
      type: Object,
    },
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    CloseImg() {},
    closePreview() {
      this.imagePreview.close();
      this.placardURL = "";
      this.$emit("closeScreenshot");
    },
    uploadShareimg(periods) {
      this.periods = periods;
      this.loading = this.$tips.loading({ text: "生成中..." });

      let url = this.$tools.getURL(this.$urls.article.shareLongChart, {
        periods,
      });
      this.$axios.get(url).then((res) => {
        this.show = true;
        this.placardURL = res.data;
        this.loading.close();

        this.imagePreview = ImagePreview({
          images: [this.placardURL],
          closeable: false,
          showIndex: false,
          closeOnPopstate: true,
          className: "ImagePreviewBlock",
          onChange: () => {
            this.placardURL = "";
          },
          onClose: (e) => {
            this.placardURL = "";
            this.$emit("closeScreenshot");
          },
          onClosed: () => {
            this.placardURL = "";
            this.$emit("closeScreenshot");
          },
        });
      });
    },
  },
};
</script>
  <style >
.screenshot_container {
  width: 100%;
  background: #eaf1fb;
  padding: 24px;
  display: flex;
  flex-direction: column;
}
.screenshot_btn {
  /* width: 4vw; */
}
.placard_preview_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8%;
  /* background-color: #2b2b2bcc; */
  z-index: 9999;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2010;
}
.placard_image {
  width: 100%;
  flex: 1 0 0;
  display: flex;
  /* justify-content: center; */
  justify-content: end;
  align-items: center;
  flex-direction: column;
}

.van-image-preview__swipe-item {
  height: 92%;
}
.placard_title {
  font-size: 3vw;
  /* margin-top: 4vw; */
  color: #fff;
}

.placard_close {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.close-preview-button {
  width: 105px;
  height: 26px;
  font-size: 13px;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  color: #fff;
  /* background: url("../../../assets/images/login/btn.png"); */
  background-size: 100% 100%;
}
.placard_preview_container .customButton_container {
  color: #fff;
  width: 40vw;
  margin: 2vw 0;
}
.placard_preview_container .customImage_container {
  background: border-box;
  /* opacity: 0.2; */
}
</style>
