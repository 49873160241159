// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {
  openid: "https://server.medflying.com/api/common/wx/openid",//openid
  jssign: 'https://server.medflying.com/api/common/wx/jssign',//获取js SDK
  oauth: '/wx/set_cookie_openid',//验证
  uploads: {
    uploadBase64: "https://files.medflying.com/file_system/filesbase64",
    uploadFile: "https://files.medflying.com/file_system/files",
  },
  login: {
    saleLogin: '/sales/login',
    saleCode: '/sales/send_code',
    userLogin: '/user/login',
    userCode: '/user/send_code',

  },
  live: {
    info: "/user/sign",
    sign: "/user/meet_sign",
    verify: "https://eye.zhoukan.medflying.com/ws/live/live_info",
  },
  sale: {
    home: '/sales/home',//管理员首页
    addDoctor: '/sales/user_add',//增加医生
    salesUserHome: '/sales/user_home',//销售-专家首页
    userHome: '/user/home',//专家首页
    patchUserCount: '/sales/patch_user_count',//调整会议次数
    userInfo: '/user/info',//get专家信息  patch完善专家信息
    doctorInfoPlus: '/user/doctor_info_plus',//完善专家信息
    auditJob: '/user/audit_job',//人工审核
    getDoctor: '/user/get_doctor',//智能匹配总库医生
  },
  courseware: {
    list: "/courseware/list",
    detail: '/user/courseware_info',
    originalHelp: '/literature/original_help'
  },
  literature: {
    list: '/literature/list',//文献 文章列表
    detail: '/literature/detail',//详情
    countInfo: '/literature/count_info',//文献 阅读数，收藏数 详情
    collect: '/literature/collect',//收藏
    like: '/literature/like',//点赞
    collectList: '/literature/my_collect',//收藏列表
    periodsList: '/literature/periods',//收藏列表
    uploadCourseware: '/sales/upload_courseware',//上传课件
    coursewareInfo: '/user/courseware_info',//课件详情
  },
  meet: {
    create: '/sales/meet_create',//创建会议
    meetInfo: '/user/meet_info',//会议详情
    meetPatch: '/sales/meet_patch',//修改会议 销售
    meetUserPatch: '/user/meet_patch',//修改会议 专家
  },
  agreement: {
    doctorSign: '/user/sign',//协议签署
    signDoctorInfo: "/user/sign_doctor_info",//协议信息

  }, article: {
    operation: '/literature/operation', // 周刊记录 type 0 跳转 1 翻译 2 回首页 3 关注 4 分享
    toHomepage: '/literature/return_to_homepage', // 返回首页
    jumpOriginal: '/literature/jump_original', //  跳转原文
    shareLongChart: '/literature/share_long_chart' //  获取长图
  }
}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
  if (typeof urls[key] == "object") {
    for (let i in urls[key]) {
      if (urls[key][i].includes("http")) continue;

      urls[key][i] = `${host}${config.urlPrefix}${urls[key][i]}`;
    }
    continue
  }
  if (urls[key].includes("http")) continue;
  urls[key] = `${host}${config.urlPrefix}${urls[key]}`;
}
export default urls;